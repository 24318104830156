require 'src/coffee/controls/map'

{MapOptions} = require 'src/coffee/controls/map/options'
{LayerSwitcherPlugin} = require 'src/coffee/controls/map/plugins/layer-switcher'
{LegendPlugin} = require 'src/coffee/controls/map/plugins/legend'

pruneObject = (obj) ->
    for k, v of obj
        if not v?
            delete obj[k]
        else if typeof v is 'object' and not Array.isArray v
            pruneObject v

setup = ({$element, options, $ctx = $(document)}) ->
    {addedToDom} = options.promises

    options = new MapOptions

    options.promises = {addedToDom}

    options.legend = do ->
        legend = $element.parent().find('.geolegend')
        if legend.length is 1
            parent = legend.parent()
            parent.find 'input[type=checkbox]'
                .change ->
                    legend.toggle()
            return legend[0]

    data = $element.data()

    optionOverrides =
        debug: true
        client: data.client
        extent: data.extent
        maxZoom: data.maxZoom
        layerOverrides:
            showGroundLayer: data.showGroundLayer
        layers:
            primary: data.layersPrimary
            secondary: data.layersSecondary
            external: data.layersExternal
            geoserver: data.layersGeoserver
            vector: data.layersVector
    # legend?
        tooltips:
            zoomToExtent: data.tooltipZoomToExtent
            zoomOut: data.tooltipZoomOut
            zoomIn: data.tooltipZoomIn
            attributions: data.tooltipAttributions
            switchLayer: data.tooltipSwitchLayer
            legend: data.tooltipLegend
            expand: data.tooltipExpand
            collapse: data.tooltipCollapse
            print: data.tooltipPrint
            webgis:
                newLine: data.tooltipWebgisNewLine
                newPolygon: data.tooltipWebgisNewPolygon
                newPoint: data.tooltipWebgisNewPoint
                gridOverlaySettingst: data.tooltipWebgisGridOverlaySettings
                toggleGrid: data.tooltipWebgisToggleGrid
                recenterGrid: data.tooltipWebgisRecenterGrid
                turnGridLeft: data.tooltipWebgisTurnGridLeft
                turnGridRight: data.tooltipWebgisTurnGridRight

                largeSpacing: data.tooltipWebgisLargeSpacing
                largeMaximumSpacing: data.tooltipWebgisLargeMaximumIncreaseReached

                smallSpacing: data.tooltipWebgisSmallSpacing
                smallMinimumSpacing: data.tooltipWebgisLargeMinimumDecreaseReached

                toggleActiveProbingPoint: data.tooltipWebgisToggleActiveProbingPoint
                activeToggleMode: data.tooltipWebgisActiveTogglingMode
                activeTogglingMode: data.tooltipWebgisActiveTogglingMode
                deleteFeature: data.tooltipWebgisDeleteFeature
                selectMode: data.tooltipWebgisSelectMode

                editingHelp: data.tooltipWebgisEditingHelp
        messages:
            noLayersDisplayed: data.msgNoLayersDisplayed
            webgis:
                editingHelp: data.msgWebgisEditingHelp
        headlines:
            miscLayer: data.headlineMiscLayer
            wmsLayer: data.headlineWmsLayer
            baseLayer: data.headlineBaseLayer
            legend: data.headlineLegend
        features:
            geotracking: data.enableMeasurement
            measurement: data.enableGeotracking
            infoClick: data.enableInfoClick
            editing: data.enableEditing
            zoomSlider: data.enableZoom
            # layerSwitcher: data.enableLayerSwitcher
        legendUrl: data.legendUrl
        IsCluster: data.isCluster
        ClusterDistance: data.clusterDistance
        plugins: [
            new LayerSwitcherPlugin
            new LegendPlugin
        ]

    pruneObject optionOverrides

    options = $.extend true, {}, options, optionOverrides

    mapcontrol = $element.mapcontrol options

    $element.find('.layerswitcher-collapse').click()

    return $.Deferred().resolve().promise()

module.exports = {
    setup
}

module.exports.__esModule = true
module.exports.default = setup
