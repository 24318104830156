mapservers = require './servers'

class MapOptions
    debug: false
    extent: '[474005, 5813747, 3059189, 6583016]'
    legend: undefined
    attributionCreatedBy: 'created by'
    maxZoom: 19
    servers:
        primary: mapservers.remote
        secondary: mapservers.alternate
    layers:
        vector: []
        primary: ''
        secondary: ''
        external: ''
    layerOverrides: {}
    tooltips:
        zoomToExtent: 'Zoom to extent'
        zoomOut: 'Zoom out'
        zoomIn: 'Zoom in'
        attributions: 'Attributions'
        switchLayer: 'Switch layer'
    headlines:
        wmsLayer: 'WMS-Layer'
        baseLayer: 'Base Layer'
    features:
        zoom: true
        layerSwitcher: true
        geotracking: false
        measurement: false
        infoClick: false
        editing: false
    plugins: []

module.exports = {
    MapOptions
}

module.exports.__esModule = true
module.exports.default = MapOptions
