# CoffeeScript

pad = (val, length, padChar = '0') ->
    val += ''
    numPads = length - val.length
    if (numPads > 0) then new Array(numPads + 1).join(padChar) + val else val

hexToInt = (hex) ->
    return parseInt '0x' + hex

intToHex = (int) ->
    return Number(int).toString(16)

rgbToHex = (rgb, separator = '+') ->
    [r, g, b] = rgb.split separator
    return '#' + [
        pad intToHex(r), 2
        pad intToHex(g), 2
        pad intToHex(b), 2
    ].join('')

hexToRgb = (hex, separator = '+') ->
    if hex.startsWith('#')
        hex = hex[1..]
    return [
        hexToInt hex[0..1]
        hexToInt hex[2..3]
        hexToInt hex[4..5]
    ].join separator

findColorIndex = (url, separator = '&') ->
    parts = url.split separator
    for part, i in parts
        if part.startsWith 'colorstring='
            return i
    return -1

getHexColorFromUrl = (url) ->
    colorIndex = findColorIndex url
    if colorIndex is -1
        return 'no color'
    return rgbToHex url.split('&')[colorIndex].split('=')[1]

updateDomLayerColor = (map, layerName, newColorParameter) ->
    $map = $ map.getTarget()
    layers = $map
        .attr 'data-layers'
        .split ';'

    layers = layers.map (layer, i) ->
        if layerName not in layer
            return layer
        colorIndex = findColorIndex layer, '!'

        parameters = layer.split '!'
        parameters[colorIndex] = newColorParameter
        return parameters.join '!'
    $map.attr 'data-layers', layers.join ';'

module.exports = {
    pad,
    hexToInt,
    intToHex,
    rgbToHex,
    hexToRgb,
    getHexColorFromUrl
}
