# CoffeeScript

{get} = require 'src/coffee/infrastructure/ajax'
{getHexColorFromUrl} = require 'src/coffee/controls/map/utilities/color'
{Plugin} = require './base'

Group = require('ol/layer/Group').default
Image = require('ol/layer/Image').default
TileWMS = require('ol/source/TileWMS').default
ImageWMS = require('ol/source/ImageWMS').default

class LegendPlugin extends Plugin
    init: (@options) ->
        @menu = @mapcontrol.slideoutmenu

        @$button = $ '<button>'
        .addClass 'legend-button'
        .attr 'title', @options.tooltips.legend
        .append '<i class="fa fa-tags">'

        @$content = $ '<div>'
        .addClass 'legend-content'
        .append '<h4>' + @options.headlines.legend + '</h4>'
        .append '<div class="legend-images">'
        #.append '<p class="text-info">Here will be the map legend</p>'

        @menu.addEntry @$button, @$content

        @bindEvents()

    bindEvents: ->
        @$content.on 'show', @redraw
        @map.on 'change', @redraw
        @map.getLayers().forEach (group) =>
            return unless group instanceof Group

            group.getLayers().forEach (layer) =>
                layer.on 'change:visible', @redraw

    addColorpicker: ($cell, mapId, layerId, defaultColor) ->
        $colorpicker = $ '<div>'
        .attr 'id', "colorPicker#{layerId}"
        .addClass 'colorPickerMapControl'
        .appendTo $cell

        $colorLink = $ '<a>'
        .addClass 'color'
        .append $('<div class="colorInner">')

        $track = $ '<div class="track">'
        .appendTo $colorpicker

        $dropdown = $ '<ul class="dropdown"><li></li></ul>'
        .appendTo $colorpicker

        $input = $ '<input class="hidden" type="hidden">'
        .attr 'data-layerId', layerId
        .attr 'data-mapName', mapId
        .attr 'value', defaultColor
        .appendTo $colorpicker

        $colorpicker.tinycolorpicker()
        colorpicker = $colorpicker.data 'plugin_tinycolorpicker'
        colorpicker.setColor defaultColor

    redraw: =>
        return unless @$button.hasClass 'active'

        $images = @$content.children('.legend-images')

        $images.empty()
        if @options.legendUrl
            get @options.legendUrl
            .then (result) =>
                $images.empty().append result
                @menu.recalculateSize()
                for img in $images.find('img')
                    img.onload = @menu.recalculateSize

        else
            for group, i in @mapcontrol.layerManager.allLayerGroups
                for layer, j in group.getLayers().getArray()
                    continue unless layer.getVisible() and (layer.getSource() instanceof ImageWMS or layer.getSource() instanceof TileWMS)

                    source = layer.getSource()
                    params = source.getParams()

                    for wmsLayer in params.LAYERS.split ','
                        url = if source.getUrl then source.getUrl() else source.getUrls()[0]
                        url += '?' unless '?' in url
                        url += "&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetLegendGraphic&FORMAT=image/png&LAYER=#{wmsLayer}"

                        if layer.get('legendUrl')
                            legendLayerName = layer.get 'legendLayerName'
                            url = layer.get('legendUrl') + "?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetLegendGraphic&FORMAT=image/png&LAYER=#{legendLayerName}"

                        $entry = $ '<div class="legend-entry clearfix">'
                        .append $ "<label>#{layer.get 'name'}</label>"
                        .appendTo $images

                        color = getHexColorFromUrl url

                        if color is 'no color'
                            $entry.append $("<div><img class='img-thumbnail' src='#{url}' /></div>")
                        else
                            mapId = @mapcontrol.$target.attr 'id'
                            layerId = "#{i},#{j}"

                            $entry.prepend $ "<div class='layer-color-marker' style='background-color: #{color};'>"
                            # @addColorpicker $cell, mapId, layerId, color
            unless $images.children().length
                $images.append "<p>#{@options.messages.noLayersDisplayed}</p>"

module.exports = {
    LegendPlugin
}

module.exports.__esModule = true
module.exports.default = LegendPlugin
